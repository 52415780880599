export async function submitSignup(data) {
    let res = null;
    try {
        res = await fetch("https://13spadesdealeracademy.com:8443/signup", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)
        });
    } catch (err) { 
        console.error(err);
    }
    return res;
}