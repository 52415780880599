import React from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { submitSignup } from "../services/signup";

class SignupModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      submitBtnDisabled: false,
      fname: '',
      lname: '',
      phone: ''
    }

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  showModal() {
    this.setState({ isOpen: true });
  };

  hideModal() {
    this.setState({ isOpen: false });
  };

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitForm();
    }
  }

  async submitForm() {
    this.setState({ submitBtnDisabled: true });
    const data = {
      fname: this.state.fname,
      lname: this.state.lname,
      phone: this.state.phone
    };
    if (data.fname && data.lname && data.phone) { 
      const res = await submitSignup(data);
      if (res.ok) this.hideModal();
    }
  }

  render() {
    return (
      <>
        <button className="btn btn-primary" onClick={this.showModal}>
          I'm interested
          <i className="bi bi-arrow-right mx-3" />
        </button>
        <Modal show={this.state.isOpen} onHide={this.hideModal} centered>
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">Send Contact Info</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
          <form>
              <div className="form-group">
                  <label htmlFor="firstname" className="col-form-label">First Name:</label>
                  <input type="text" className="form-control" id="firstname" name="fname" value={this.state.fname} onChange={this.handleInputChange} />
              </div>
              <div className="form-group">
                  <label htmlFor="lastname" className="col-form-label">Last Name:</label>
                  <input type="text" className="form-control" id="lastname" name="lname" value={this.state.lname} onChange={this.handleInputChange}  />
              </div>
              <div className="form-group">
                  <label htmlFor="phone" className="col-form-label">Phone Number:</label>
                  <input type="text" className="form-control" id="phone" name="phone" value={this.state.phone} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
              </div>
          </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button id="submit" type="button" className="btn btn-success" onClick={this.submitForm} disabled={this.state.submitBtnDisabled}>Submit</button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SignupModal;