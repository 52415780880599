export async function downloadEBook(secret) {
    let res = null;
    try {
        res = await fetch("https://api.13spadesdealeracademy.com:8443/download_ebook", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ secret: secret })
        });
    } catch (err) { 
        console.error(err);
    }
    return res;
}