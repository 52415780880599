import { useEffect } from "react";
import { Link } from "react-router-dom";
import ContactSection from "./contactSection";
import '../styles/home.css'
import aos from 'aos'
import 'aos/dist/aos.css'


import img_intro from '../images/13ofspades_cz169.jpg'
import img_sched from '../images/chipcut_cz169.jpg'
import img_hs43 from '../images/IMG_4967_43.jpg'

const Home = () => {
    useEffect(() => {
        aos.init({ once: true });
    }, []);

    return (
        <div className="home">
            <header className="home-welcome bg-fixed">
                <div 
                    id="home-welcome-overlay"
                    className='container text-center text-light gap-3'
                    data-aos='fade-zoom-in'
                    data-aos-duration='1000'
                >
                    <div className='row justify-content-center'>
                        <div className='col-lg-10 text-cdf text-center'>
                            <h1 className='mb-5'>Welcome to 13 Spades Dealer Academy</h1>
                            <p>Ever wanted to become a poker dealer? Begin learning today and earn your certificate in six weeks!</p>
                            <Link to='/pricing' className='col-8 col-lg-4 btn btn-primary'>
                                Get Started
                                <i className="bi bi-arrow-right mx-3" />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <main className='home-content'>
                <section className='home-intro tsection bg-light shadow'>
                    <div className='container-xl px-0 py-3'>
                        <div className="card my-3 border-0 shadow">
                            <div className="row g-0 bg-grad-cdf-t rounded-3">
                                <div className="row col-lg-6 p-0 mx-auto rounded-3">
                                    <div 
                                        className="card-body h-100 d-flex flex-column align-items-center justify-content-around text-center px-2 py-3"
                                        data-aos='fade-left'
                                        data-aos-easing='ease-in'
                                        data-aos-duration='800'
                                        data-aos-anchor-placement="bottom-bottom"
                                    >
                                        <h1 className='m-0'>
                                            Become a Certified Poker Dealer
                                        </h1>
                                        <hr className="w-100" />
                                        <p>
                                            Are you interested in becoming a poker dealer?
                                            Are you looking for a <strong>fun</strong> new career?
                                            Poker dealers are in high demand all across the country.
                                            Sign up now and you could be making $30 - $50 per hour as a certified poker dealer.
                                        </p>
                                        <Link 
                                            to='/about'
                                            className='btn btn-primary w-50 mt-3'
                                            data-aos='fade-up'
                                        >
                                            Learn More
                                            <i className="bi bi-arrow-right mx-3" />
                                        </Link>
                                    </div>
                                </div>
                                <div 
                                    className="col-lg-5 col-12"
                                    data-aos='fade-right'
                                    data-aos-easing='ease-in'
                                    data-aos-duration='800'
                                    data-aos-anchor-placement="bottom-bottom"
                                >
                                    <img src={img_intro} className="img-fluid rounded" alt="poker table" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='secdiv' />
                <section className='home-sched tsection bg-light shadow'>
                    <div className='container-xl px-0 py-3'>
                        <div className="card my-3 border-0 shadow">
                            <div className="row g-0 bg-grad-cdf-t rounded-3">
                                <div 
                                    className="col-lg-5 col-12"
                                    data-aos='fade-left'
                                    data-aos-easing='ease-in'
                                    data-aos-duration='800'
                                    data-aos-anchor-placement="bottom-bottom"
                                >
                                    <img src={img_sched} className="img-fluid rounded" alt="counting chips" />
                                </div>
                                <div className="row col-lg-6 p-0 mx-auto rounded-3">
                                    <div 
                                        className="card-body h-100 d-flex flex-column align-items-center justify-content-around text-center px-2 py-3"
                                        data-aos='fade-right'
                                        data-aos-easing='ease-in'
                                        data-aos-duration='800'
                                        data-aos-anchor-placement="bottom-bottom"
                                    >
                                        <h1>Schedule</h1>
                                        <hr className='w-100' />
                                        <h4>The full course is 6 weeks long.</h4>
                                        <p className=''>
                                            Day classes: Monday &amp; Tuesday 11AM-4PM
                                            <br />
                                            Night classes: Monday &amp; Tuesday 5PM-10PM
                                        </p>
                                        <Link 
                                            to='/pricing'
                                            className='btn btn-primary w-50'
                                            data-aos='fade-up'
                                        >
                                            Sign Up Today
                                            <i className="bi bi-arrow-right mx-3" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='secdiv' />
                <section className='home-about tsection bg-light'>
                    <div className='container-xl px-0 py-3'>
                        <div className="card my-3 border-0 shadow">
                            <div className="row g-0 bg-grad-cdf-t rounded-3">
                                <div className="row col-lg-6 p-0 mx-auto rounded-3">
                                    <div 
                                        className="card-body h-100 d-flex flex-column align-items-center justify-content-around text-center px-2 py-3"
                                        data-aos='fade-left'
                                        data-aos-easing='ease-in'
                                        data-aos-duration='800'
                                        data-aos-anchor-placement="bottom-bottom"
                                    >
                                        <h1 className='m-0'>
                                            About Us
                                        </h1>
                                        <hr className="w-100" />
                                        <p>
                                            My name is Doug Price and I will be your instructor here at 13 Spades
                                            Dealer Academy. I have worked at multiple casino poker rooms in every capacity
                                            including as a Dealer, Floor Supervisor, and Poker Room Manager. I have 10 years
                                            of experience in this fun and exciting industry and I would love to help you become a part of it.
                                        </p>
                                        <Link 
                                            to='/about'
                                            className='btn btn-primary w-50'
                                            data-aos='fade-up'
                                        >
                                            Read More
                                            <i className="bi bi-arrow-right mx-3" />
                                        </Link>
                                    </div>
                                </div>
                                <div 
                                    className="col-lg-5 col-12"
                                    data-aos='fade-right'
                                    data-aos-easing='ease-in'
                                    data-aos-duration='800'
                                    data-aos-anchor-placement="bottom-bottom"
                                >
                                    <img src={img_hs43} className="img-fluid rounded" alt='Company Owner' />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='secdiv' />
                <section className='home-teach bg-grad-cdf-t'>
                    <div className='container-xl px-0 py-3'>
                        <div className="ccm row g-0 bg-dark shadow rounded justify-content-center align-items-center">
                            <div className='col-md-10 text-light text-center p-2'>
                                <h1 className=''>What You'll Learn</h1>
                                <hr className='w-100' />
                                <p className='text-info'>All students will receive one deck of your choice of Premium Casino Grade playing cards and cut card</p>
                                <div className='row justify-content-evenly'>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr  className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade fgrad flame mx-3" />
                                            Game Types
                                        </h4>
                                        <p>
                                            From the basic rules of Texas Hold 'em and 5 Card Draw to the specifics
                                            of Pot Limit Omaha and 7 Card Stud, you will learn the ins and outs of each
                                            type of poker game in order to better understand how to deal each game optimally.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade-fill fgrad flash mx-3" />
                                            Mechanics
                                        </h4>
                                        <p>
                                            You will learn how to properly hold, pitch, and shuffle cards, how to quickly and 
                                            properly count out chips and cash, and learn the most efficient dealing techniques 
                                            during every step of every turn to maximize your profit.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade fgrad flame mx-3" />
                                            Betting Structures
                                        </h4>
                                        <p>
                                            You will learn about each type of betting structure including Limit,
                                            No Limit, Pot Limit, and Spread Limit.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />    
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade-fill fgrad flash mx-3" />
                                            Advanced Mechanics
                                        </h4>
                                        <p>
                                            We teach you the most optimized mechanics - every hand movement and
                                            every calculation - used by the most successful professional poker dealers 
                                            in order to minimize mechanical mistakes and logical errors that could slow down the game
                                            and to maximize the amount of hands you get to deal every hour.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade-fill fgrad flash mx-3" />
                                            Advanced Ruling
                                        </h4>
                                        <p>
                                            You will learn how to handle and make decisions regarding special situations that, 
                                            without being previously aware, would otherwise require intervention that slows down the game 
                                            and ultimately costs you time and impacts your profit.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade fgrad flame mx-3" />
                                            Cash Games
                                        </h4>
                                        <p>
                                            Learn about how cash games are operated including how to handle cash and 
                                            process buy-ins and how to quickly perform rack maintenance.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade-fill fgrad flash mx-3" />
                                            Tournaments
                                        </h4>
                                        <p>
                                            Learn about common poker tournament structure, rules, and the best practices
                                            for dealing tournament games.
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                    <div className='col-lg-4 col-sm-5 my-2' data-aos='fade-up' data-aos-duration='1000' data-aos-anchor-placement="center-center">
                                        <hr className='bg-info' />
                                        <h4 className=''>
                                            <i className="bi bi-suit-spade fgrad flame mx-3" />
                                            Audition
                                        </h4>
                                        <p>
                                            You will have the opportunity to spend as much time as you need in the dealer's seat
                                            to prepare you for your audition. 
                                        </p>
                                        <hr className='bg-info' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactSection />
            </main>
        </div>
    );
}

export default Home;