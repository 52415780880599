import '../styles/footer.css'

const Footer = () => {

    return (
        <div id="footer" className='bg-dark text-light text-center p-3'>
            <p className='mb-0'>&copy; 13 Spades Dealer Academy 2022</p>
        </div>
    );
}

export default Footer;