import React from "react";
import '../styles/about.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../styles/contact.css'



const Contact = () => {

    return(
        <div>
            <header className="contact-header bg-fixed-2">
                <div id="contact-header-overlay" className='container h-100 text-center text-cdf'>
                    <div className='row h-100 justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <h1>Contact</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className='contact-content bg-dark text-light'>
                <section className='container-xl'>
                    <div className='row gap-2 justify-content-center p-5'>
                        <div className='row col-lg-5 col-10 justify-content-center'>
                            <h1>Contact Us</h1>
                            <hr />
                            <p>
                                <i className="bi bi-geo-alt-fill p-2 text-info" />
                                1575 South Main St, Washington, PA 15301
                            </p>
                            <p>
                                <i className="bi bi-telephone-fill p-2 text-info" />
                                Call or Text (304)-281-4451
                            </p>
                            <p>
                                <i className="bi bi-envelope-fill p-2 text-info" />
                                13spadesdealeracademy@gmail.com
                            </p>
                        </div>
                        {/* <div className='col-lg-6 col-10'>
                            <iframe
                                className='w-100'
                                height={320}
                                title="Map"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBUnlKv3OSiQMF_aol9TeJF0psdWO3NBAs&q=1575+South+Main+Street,Washington+PA"
                            />
                        </div> */}
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Contact;