import React from "react";
import { Link } from "react-router-dom";
import '../styles/nav.css';
import logo from '../images/logo-tp.png'

const Nav = () => {

    return(
        <nav className="navbar navbar-expand-md p-0 navbar-dark bg-dark">
            <div className="container-sm">
                <Link id='nav-title' className="navbar-brand text" to="/">
                    <img src={logo} className='me-2' height="40" width="40" alt="logo" />
                    {/* <h5 className='d-inline'> */}
                        13 Spades Dealer Academy
                    {/* </h5> */}
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <Link className='nav-item nav-link' to='/'>
                            Home
                        </Link>
                        <Link className='nav-item nav-link' to='/about'>
                            About
                        </Link>
                        <Link className='nav-item nav-link' to='/pricing'>
                            Pricing
                        </Link>
                        <Link className='nav-item nav-link' to='/contact'>
                            Contact
                        </Link>
                </div>
            </div>
        </nav>
    );
}

export default Nav;